@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

@layer components {



  /*
   * Modal repeatable styles
   */
  .modalContainerOuter {
    @apply relative inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full;
  }

  .modalContainerMiddle {
    @apply grid grid-cols-12 gap-6 p-5 w-full;
  }

  .modalContainerInner {
    @apply col-span-12 border-b border-lightgray;
  }

  /*
   * Input fields repeatable styles
   */
  .labelDefault {
    @apply whitespace-nowrap flex items-center text-sm;
  }
  .selectDefault {
    @apply ml-3 text-xs font-medium text-gray-900;
  }
  .inputDefault {
    @apply p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm;
  }

  .greyButton {
    @apply disabled:pointer-events-none disabled:opacity-25 w-full whitespace-nowrap inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 hover:text-orange-900;
  }
  .addButton {
    @apply whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500;
  }

  /*
   * Sidebar styles
   */
  .footerEndButton {
    @apply flex p-3 text-xs font-medium text-gray-600 hover:text-orange-900;
  }

  /* 
   * Add Client modal
   */
  .addClientModalLabel {
    @apply col-span-1 flex items-center;
  }
  .addClientModalInput {
    @apply col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min;
  }
}

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}

@layer utilities {
  @font-face {
    font-family: "Raleway", sans-serif;
  }

  @font-face {
    font-family: "Times", sans-serif;
  }

  @font-face {
    font-family: "Schlagzeilen", sans-serif;
  }

  @font-face {
    font-family: "Absatzschriftart", sans-serif;
  }

  .ag-theme-alpine .ag-header-viewport {
    background: #2989d8 !important;
  }

  html {
    font-family: "Raleway", sans-serif;
  }

  .hero {
    font-family: "Times", sans-serif;
    font-weight: bold;
    font-size: 54px;
  }

  h1 {
    font-family: "Times", sans-serif;
    font-weight: bold;
    font-size: 54px;
  }

  h2 {
    font-family: "Times", sans-serif;
    font-weight: bold;
    font-size: 32px;
  }

  h3 {
    font-family: "Times", sans-serif;
    font-weight: bold;
    font-size: 24px;
  }

  h4 {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 21px;
  }

  .subtitle {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
  }

  link {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
  }

  .overline {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }

  .labeltest {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;
    pointer-events: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.jodit-react-container {
  font-weight: normal;
  font-size: 16px;
}

.jodit-wysiwyg {
  padding-left: 10px;
}

.jodit-wysiwyg ul,
.jodit-wysiwyg ol {
  list-style: inside;
}

.jodit-wysiwyg ol {
  list-style-type: number;
}

.jodit-wysiwyg td {
  border: solid 1px;
}



.my-row-class{
  display:flex !important;
  align-items: center !important;
}

.my-cell-class{
  margin: 0 !important;
  display:flex !important;
  align-items: center !important;
  /* height: 27px !important; */
}

.customized-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}


.css-70qvj9>[type="text"],
.css-70qvj9>[type="tel"] {
  border-width: 0 0 1px 0;
  border-color: #d1d5db;
  background-color: #f9fafb;
  padding: 2px 4px;
}

.css-70qvj9>[type="text"]:disabled,
.css-70qvj9>[type="tel"]:disabled {
  border-width: 0;
  margin-bottom: 0px;
}

.css-70qvj9>[type="text"]:focus,
.css-70qvj9>[type="tel"]:focus {
  border-width: 0 0 1px 0;
  border-color: #2989D8;
  border-style: solid;
}

.css-70qvj9>[type="text"]:hover:enabled,
.css-70qvj9>[type="tel"]:hover:enabled {
  border-width: 0 0 1px 0;
  margin-bottom: 0px;
  border-color: #2989D8;
  background-color: #EAF3FB;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: black !important;
}

.pro-sidebar-layout {
  justify-content: space-between !important;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-header-active [class^="ag-"]:hover {
  color: white !important;
}

.header-blue {
  background-color: #2989d8;
  color: #fff;
}

.rdw-editor-main {
  min-height: 150px;
}

/* mui forms full width*/
.css-i44wyl {
  width: 100%;
}

.zindexfix {
  z-index: 9999 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  margin-left: 15px !important;
  border: 0 !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

[type="tel"]:focus {
  outline: none !important;
  --tw-ring-color: transparent !important;
}

[type="text"]:focus {
  outline: none !important;
  --tw-ring-color: transparent !important;
}

[type="number"]:focus {
  outline: none !important;
  --tw-ring-color: transparent !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cke_notifications_area {
  display: none !important;
}

/*delite the color for autofill selected*/
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

textarea:focus {
  outline: none !important;
  --tw-ring-color: transparent !important;
}

.pro-sidebar {
  color: black;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #1d1d1d00;
  height: 100%;
  position: relative;
  z-index: 101;
}
.pro-sidebar > .pro-sidebar-inner > img.sidebar-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  opacity: 0.3;
  left: 0;
  top: 0;
  z-index: 100;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  flex-grow: 1;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border-top: 1px solid rgba(173, 173, 173, 0.2);
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.pro-sidebar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
}
.pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
.pro-sidebar.rtl {
  text-align: right;
  direction: rtl;
}
@media (max-width: 480px) {
  .pro-sidebar.xs {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.xs.collapsed {
    left: -80px;
  }
  .pro-sidebar.xs.toggled {
    left: 0;
  }
  .pro-sidebar.xs.toggled .overlay {
    display: block;
  }
  .pro-sidebar.xs.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.xs.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.xs.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 576px) {
  .pro-sidebar.sm {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.sm.collapsed {
    left: -80px;
  }
  .pro-sidebar.sm.toggled {
    left: 0;
  }
  .pro-sidebar.sm.toggled .overlay {
    display: block;
  }
  .pro-sidebar.sm.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.sm.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.sm.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 768px) {
  .pro-sidebar.md {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.md.collapsed {
    left: -80px;
  }
  .pro-sidebar.md.toggled {
    left: 0;
  }
  .pro-sidebar.md.toggled .overlay {
    display: block;
  }
  .pro-sidebar.md.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.md.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.md.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 992px) {
  .pro-sidebar.lg {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.lg.collapsed {
    left: -80px;
  }
  .pro-sidebar.lg.toggled {
    left: 0;
  }
  .pro-sidebar.lg.toggled .overlay {
    display: block;
  }
  .pro-sidebar.lg.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.lg.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.lg.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 1200px) {
  .pro-sidebar.xl {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.xl.collapsed {
    left: -80px;
  }
  .pro-sidebar.xl.toggled {
    left: 0;
  }
  .pro-sidebar.xl.toggled .overlay {
    display: block;
  }
  .pro-sidebar.xl.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.xl.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.xl.rtl.toggled {
    left: auto;
    right: 0;
  }
}
@media (max-width: 1600px) {
  .pro-sidebar.xxl {
    position: fixed;
    left: -270px;
  }
  .pro-sidebar.xxl.collapsed {
    left: -80px;
  }
  .pro-sidebar.xxl.toggled {
    left: 0;
  }
  .pro-sidebar.xxl.toggled .overlay {
    display: block;
  }
  .pro-sidebar.xxl.rtl {
    left: auto;
    right: -270px;
  }
  .pro-sidebar.xxl.rtl.collapsed {
    left: auto;
    right: -80px;
  }
  .pro-sidebar.xxl.rtl.toggled {
    left: auto;
    right: 0;
  }
}

.pro-sidebar
  .pro-menu.submenu-bullets
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  content: "";
  display: inline-block;
  width: 4px;
  min-width: 4px;
  height: 4px;
  border: 1px solid #2b2b2b;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  box-shadow: 1px 0px 0px white, 0px -1px 0px white, 0px 1px 0px white,
    -1px 0px 0px white;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pro-sidebar .pro-menu {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #cccfcf;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: white;
}
.pro-sidebar .pro-menu a:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: black;
}
.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}
.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
  display: flex;
  margin-right: 5px;
  opacity: 1;
  transition: opacity 0.2s;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 20px !important;
  cursor: pointer;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  outline: none;
  color: black;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 10px;
  font-size: 12px;
  width: 26px;
  min-width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  display: inline-block;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper
  .pro-icon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
}

/* here hover the menu items */
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: black;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item:hover
  .pro-icon-wrapper
  .pro-icon {
  animation: swing ease-in-out 0.5s 1 alternate;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item:before {
  background: white;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-color: black;
  border-width: 0 2px 2px 0;
  padding: 2.5px;
  vertical-align: middle;
  transition: transform 0.3s;
  transform: rotate(-45deg);
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu.open
  > .pro-inner-item:before {
  background: transparent !important;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu.open
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  transform: rotate(45deg);
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 24px;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 8px 30px 8px 15px;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item
  > .pro-icon-wrapper {
  background: none;
  width: auto;
  min-width: auto;
  height: auto;
  line-height: auto;
}
.pro-sidebar
  .pro-menu:not(.inner-submenu-arrows)
  .pro-inner-list-item
  .pro-menu-item.pro-sub-menu
  .pro-inner-item
  .pro-arrow-wrapper {
  display: none;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #2b2b2b;
}
.pro-sidebar
  .pro-menu.square
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  border-radius: 0;
}
.pro-sidebar
  .pro-menu.round
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  border-radius: 4px;
}
.pro-sidebar
  .pro-menu.circle
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  border-radius: 50%;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item {
  position: relative;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item
  > .pro-inner-item
  > .suffix-wrapper,
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item
  > .pro-inner-item
  > .prefix-wrapper {
  opacity: 0;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
  background-color: #2b2b2b;
  z-index: 111;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu {
  position: relative;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-item {
  pointer-events: none;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper {
  display: none;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item {
  height: auto !important;
  position: fixed;
  visibility: hidden;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
  padding-left: 3px;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item.has-arrow {
  padding-left: 10px;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  max-height: 100vh;
  overflow-y: auto;
  background-color: #2b2b2b;
  padding-left: 20px;
  border-radius: 4px;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu:hover
  > .pro-inner-list-item {
  transition: visibility, transform 0.3s;
  visibility: visible;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu:hover
  .pro-icon-wrapper
  .pro-icon {
  animation: swing ease-in-out 0.5s 1 alternate;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-sub-menu-item,
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 8px 30px 8px 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 20px 8px 35px;
}
.pro-sidebar.rtl
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  margin-right: 0;
  margin-left: 10px;
}

.pro-sidebar.rtl
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper {
  right: auto;
  left: 20px;
}
.pro-sidebar.rtl
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  transform: rotate(135deg);
}

.pro-sidebar.rtl
  .pro-menu
  .pro-menu-item.pro-sub-menu.open
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  transform: rotate(45deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 24px;
}
.pro-sidebar.rtl
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 8px 15px 8px 30px;
}
.pro-sidebar.rtl
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  margin-right: 0;
  margin-left: 15px;
}

.pro-sidebar.rtl.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px;
}
.pro-sidebar.rtl.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item.has-arrow {
  padding-right: 10px;
}
.pro-sidebar.rtl.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  padding-left: 0;
  padding-right: 20px;
}

.pro-sidebar.rtl.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 8px 5px 8px 30px;
}

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^="left"] > .popper-arrow {
  right: 0;
  border-right: 7px solid #2b2b2b;
}

.popper-element[data-popper-placement^="right"] > .popper-arrow {
  left: 0;
  border-left: 7px solid #2b2b2b;
}

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}

/*
 * TinyMCE style tweaks
 */

.tox-tinymce {
  border: 1px solid #eee !important;
  border-radius: 0px !important;
}
.tox:not(.tox-tinymce-inline),
.tox-editor-header {
  border-bottom: 1px solid #eee !important;
  box-shadow: 0px !important;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  border-bottom: 0px !important;
}


.rechnungenGrid span.ag-paging-row-summary-panel {
  display: none;
}